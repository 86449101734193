<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20" ref="searchForm" :model="searchForm">
      <FormItem prop="no">
        <span class="label">发票单号：</span>
        <i-input v-model="searchForm.no" placeholder="请输入" class="iviewIptWidth250" clearable></i-input>
      </FormItem>
      <FormItem prop="selectData">
        <span class="label">开票时间：</span>
        <!-- <DatePicker type="datetime" class="iviewIptWidth250" placeholder="请选择" v-model="begin_date"></DatePicker> 至 <DatePicker type="datetime" class="iviewIptWidth250" placeholder="请选择" v-model="end_date"></DatePicker> -->
        <DatePicker type="daterange" split-panels placeholder="请输入" class="iviewIptWidth250" v-model="searchForm.selectData"></DatePicker>
      </FormItem>
      <FormItem class="po-create-number" :label-width="10">
        <span class="pageBtn finger btnSure" @click="searchBillInformation">查询</span>
        <!-- <span class="pageBtn finger btnReset marginLeft20" @click="goDetails('1')" v-if="insertAble">新增</span> -->
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :isLoad="isLoad" totalText="条记录">
      <template slot-scope="{ row }" slot="relevance">
        <Poptip word-wrap width="200" :content="row.documents_str" trigger="hover" placement="top">
          <span>{{ row.documents_cut }}</span>
        </Poptip>
      </template>
      <div slot="summary" class="summary" v-if="listData && listData.length > 0">
        <div>
          合计金额：
          <span class="color389">{{ $utils.formatMoney(totleMoney, true) }}</span>
        </div>
      </div>
    </Table>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'billingInformation',
  components: {
    Table
  },
  data() {
    return {
      isLoad: false,
      time: '',
      /* 搜索表单 */
      searchForm: {
        /* 发票号 */
        no: '',
        /* 选择的日期数集 */
        selectData: []
      },
      /* 表头信息 */
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75
        },
         {
          title: '发票单号',
          key: 'oddnum',
          align: 'center'
        },
        {
          title: '发票代码',
          key: 'invoice_code',
          align: 'center'
        },
        {
          title: '发票号码',
          key: 'invoice_number',
          align: 'center'
        },
        {
          title: '发票金额',
          align: 'center',
          render: (h, param) => {
            let str = ''
            if (param.row.money == null) {
              str = ''
            } else if (param.row.money == 0) {
              str = '¥0.00'
            } else {
              str = this.$utils.formatMoney(param.row.money, true)
            }
            return h('span', str)
          }
        },
        {
          title: '开票时间',
          key: 'time',
          align: 'center',
          render: (h, param) => {
            let str = ''
            str = this.$moment.unix(param.row.time).format('YYYY-MM-DD')
            return h('span', str)
          }
        },
        {
          title: '备注',
          key: 'note',
          align: 'center'
        },
        {
          title: '操作',
          align: 'center',
          width: 220,
          render: (h, param) => {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableFont',
                    on: {
                    click: () => {
                    this.$router.push({
                    path: '/lookListTable',
                    query:{
                      invoice_id:param.row.invoice_id
                    }
                  })
                  },
                },
                  },
                  '详情'
                ),
              ])
          }
        }
      ],
      /* 表格数据 */
      listData: [],
      total: 0,
      pages: {
        page: 1,
        rows: 10
      },
      totleMoney: 0, // 总金额
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false // 查找权限
    }
  },
  methods: {
    goDetails(type, id) {
      this.$router.push({
        path: '/billingDetails',
        query: {
          type: type, // 1新增 2查看 3编辑
          id: type === '1' ? '' : id
        }
      })
    },
    changePage(e) {
      this.pages.page = e
      this.getBillingInformation(this.page, this.searchForm)
    },
    getBillingInformation(item) {
      let obj = Object.assign(this.pages, item)
      obj.supplier_id = JSON.parse(window.localStorage.getItem('userInfo')).company_id
      this.$http.get(this.$api.supplierInvoice, obj).then(res => {
        if (res.status) {
          // let billingList = []
          // for (const item of res.data) {
          //   let no = item.invoice_no
          //   let time = item.invoice_time ? this.$moment.unix(item.invoice_time).format('YYYY-MM-DD') : ''
          //   let money = item.invoice_amount
          //   let invoice_status = item.invoice_status
          //   let documents_cut = item.documents_cut
          //   let documents_str = item.documents_str
          //   let obj = { no, time, money, invoice_status, documents_cut, documents_str }
          //   billingList.push(obj)
          // }
          this.total = res.data.total
          this.listData = res.data.invoice
          this.totleMoney = res.data.total_count
        }
      })
    },
    /* 搜索发票信息 */
    searchBillInformation() {
      let item = {}
      item.invoice_no = this.searchForm.no
      item.begin_date = this.$moment(this.searchForm.selectData[0]).format('YYYY-MM-DD')
      if (item.begin_date === 'Invalid date') {
        item.begin_date = ''
      }
      item.end_date = this.$moment(this.searchForm.selectData[1]).format('YYYY-MM-DD')
      if (item.end_date === 'Invalid date') {
        item.end_date = ''
      }
      item.documents = this.searchForm.documents
      this.pages = { page: 1, rows: 10 }
      this.getBillingInformation(item)
      // this.$refs.searchForm.resetFields()
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = rightStr.modify
      this.searchAble = rightStr.search
    }
  },
  created() {
    // this.searchForm.company_id = window.localStorage.getItem('userInfo').company_id
    this.getBillingInformation()
    this.getRightVisibal()
  },
  activated () {
    if(sessionStorage.getItem('updateList') == 0) {
      console.log('8989')
      this.getBillingInformation()
      sessionStorage.setItem('updateList','1')
    }
  }
}
</script>

<style scoped lang="less"></style>
